import "./App.css";
import React, { useRef } from "react";

import compas from "./Media/compas.png";
import sea from "./Media/sea.mp4";

import seaPrev from "./Media/seaPrev.png";

function App() {
  // const videoRef = useRef();
  // const handleProgres = () => {
  //   let currentTime = videoRef.current.currentTime;
  //   console.log(currentTime);
  //   if (currentTime > 6.5) {
  //     videoRef.current.pause();
  //   }
  // };

  return (
    <>
      <video
        // onTimeUpdate={handleProgres}
        // ref={videoRef}
        src={sea}
        poster={seaPrev}
        autoPlay
        loop
        muted
        preload="true"
      />
      <div className="content">
        <header className="App-header">
          <img src={compas} />
        </header>
        <div className="body">
          <h1>Busola</h1>
          <h2>Find your path</h2>
          <h3>Stay tuned... we’re building.</h3>
        </div>
        <div className="footer">
          <p>~ www.busola.org ~</p>
        </div>
      </div>
    </>
  );
}

export default App;
